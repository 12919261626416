import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarne ploče - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarne/silikatne ploče najčešće se koriste kod protupožarnog oblaganja ventilacijskih kanala, izradu kanala za odimljavanje, protupožarnu zaštitu čeličnih konstrukcija i nosivih dijelova, mogu se upotrijebiti i za oblaganje kabelskih polica sa svrhom postizanja zahtjeva vatrootpornosti.',
}

const Homepage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarne-ploce/`, name: 'Protupožarne ploče '},
      ]}
    />
    <Hero
      description="Protupožarne/silikatne ploče najčešće se koriste kod protupožarnog oblaganja ventilacijskih kanala, izradu kanala za odimljavanje, protupožarnu zaštitu čeličnih konstrukcija i nosivih dijelova, mogu se upotrijebiti i za oblaganje kabelskih polica sa svrhom postizanja zahtjeva vatrootpornosti."
      slim
      subtitle="Protupožarne ploče"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarne-ploce" />
  </Page>
)

export default Homepage